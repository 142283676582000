import Resizer from 'react-image-file-resizer'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import apiClient from '../../shared-components/utils/ApiClient'
import i18n from '../../config/i18n'

// Used in actions/rsvp.js, don't use hooks here
export const useMultimediaLogic = ({
  media,
  setMedia,
  dispatch,
  event,
  multiple = true,
}) => {
  const limitFileSize = (file) =>
    new Promise((resolve) => {
      const format = file.type === 'image/png' ? 'PNG' : 'JPEG'
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        format,
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'blob',
      )
    })

  const loadImageRatio = (url) =>
    new Promise((resolve, reject) => {
      try {
        const img = document.createElement('img')
        img.onload = function () {
          const ratio = this.width / this.height
          resolve(ratio)
        }
        img.src = url
      } catch (e) {
        reject(e)
      }
    })

  const loadVideoRatio = (url) =>
    new Promise((resolve, reject) => {
      try {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          const ratio = this.videoWidth / this.videoHeight
          resolve(ratio)
        }

        video.onerror = function () {
          reject('Invalid video. Please select a video file.')
        }

        video.src = url
      } catch (e) {
        reject(e)
      }
    })

  const uploadImage = async (_files, userGenerated = true) => {
    const isList = Symbol.iterator in Object(_files)
    let newFiles = (media ?? []).map((m) => ({
      ...m,
      removed: !multiple || !m.userGenerated ? true : m.removed,
    }))
    const files = isList ? _files : [_files]

    // Count only non-removed files
    const activeFileCount = newFiles.filter((m) => !m.removed).length
    if (activeFileCount + files.length > 10) {
      dispatch(setSnackbar('error', i18n.t('common:notMoreThan10Files')))
      return
    }

    let highestPosition = Math.max(
      ...media.map((m) => m.position).filter((p) => p != null),
      0,
    )
    for (const file of files) {
      highestPosition += 1
      let limitedFile
      if (
        file.type === 'video/mp4' ||
        file.type === 'video/quicktime' ||
        file.type === 'video/webm'
      ) {
        limitedFile = file
        if (limitedFile.size > 209715200) {
          dispatch(setSnackbar('error', i18n.t('common:fileTooBig')))
          return
        }
        const url = URL.createObjectURL(limitedFile)

        const ratio = await loadVideoRatio(url)

        newFiles = [
          ...newFiles,
          {
            id: Math.random() * 100 * Math.random(),
            position: highestPosition,
            newUrl: url,
            type: 'VIDEO',
            contentType: file.type,
            content: {
              ratio: ratio,
              fileType: file.type,
              hdVideo: {
                url: url,
              },
            },
          },
        ]
      } else {
        if (file.type === 'image/gif') {
          limitedFile = file // Don't resize GIFs
        } else {
          const limitedImg = await limitFileSize(file)
          limitedFile = new File([limitedImg], file.name, { type: file.type })
        }
        const url = URL.createObjectURL(limitedFile)

        const ratio = await loadImageRatio(url)

        newFiles = [
          ...newFiles,
          {
            id: Math.random() * 100 * Math.random(),
            newUrl: url,
            position: highestPosition,
            contentType: file.type,
            type: 'IMAGE',
            userGenerated: userGenerated,
            content: {
              imageUrls: {
                lg: url,
              },
              ratio: ratio,
              fileType: file.type,
            },
          },
        ]
      }
    }
    setMedia(newFiles)
    // setImageModalOpen(false)
  }

  const onRemoveImageClicked = (item) => {
    setMedia(
      media.map((m) => ({
        ...m,
        removed: m.id === item.id ? true : m.removed,
      })),
    )
  }

  const loadVideo = (url) =>
    new Promise((resolve, reject) => {
      try {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
          resolve(this)
        }

        video.onerror = function () {
          reject('Invalid video. Please select a video file.')
        }

        video.src = url
      } catch (e) {
        reject(e)
      }
    })

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  const uploadMedia = async (
    eventId,
    postId,
    inviteId = null,
    clearAfter = false,
  ) => {
    const oldMedia = media?.filter((m) => !m.newUrl)
    if (eventId) {
      await apiClient.event.media.patchPositions(
        eventId,
        oldMedia.map((m) => ({
          id: m.id,
          position: m.position,
        })),
      )
    } else if (postId) {
      await apiClient.post.media.patchPositions(
        postId,
        oldMedia.map((m) => ({
          id: m.id,
          position: m.position,
        })),
        event.myGuest?.code,
      )
    } else if (inviteId) {
      await apiClient.invite.media.patchPositions(
        inviteId,
        oldMedia.map((m) => ({
          id: m.id,
          position: m.position,
        })),
      )
    }

    const removedMedia = media?.filter((m) => m.removed === true) ?? []
    if (removedMedia.length > 0) {
      try {
        await Promise.all(
          removedMedia.map(async (m) => {
            if (eventId != null) {
              await apiClient.event.media.removeMedia(eventId, m.id)
            } else if (postId != null) {
              await apiClient.post.media.removeMedia(
                postId,
                m.id,
                event.myGuest?.code,
              )
            } else if (inviteId != null) {
              await apiClient.invite.media.removeMedia(inviteId, m.id)
            }
          }),
        )
      } catch (e) {
        console.log('Could not remove media')
      }
    }

    const mediaToAdd = media?.filter(
      (m) => !!m.newUrl && !m.removed && !m.uploaded,
    )
    if (!mediaToAdd || mediaToAdd.length === 0) {
      return 0
    }

    const mediaByPosition = new Map(mediaToAdd.map((m) => [m.position, m]))
    let savedMedia = null
    try {
      savedMedia = eventId
        ? await apiClient.event.media.add(eventId, mediaToAdd)
        : postId
          ? await apiClient.post.media.add(
              postId,
              mediaToAdd,
              event.myGuest?.code,
            )
          : await apiClient.invite.media.add(inviteId, mediaToAdd)
    } catch (e) {
      console.log('ERROR')
      dispatch(setSnackbar('error', i18n.t('common:imageError.general')))
      return 1
    }
    const results = await Promise.all(
      savedMedia.map(async (saved) => {
        console.log(`Uploading position ${saved.position}, type: ${saved.type}`)
        const localMedia = mediaByPosition.get(saved.position)

        if (localMedia == null) {
          console.log('Media not found by position')
          return false
        }
        const file = await fetch(localMedia.newUrl)
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], 'filename', {
                type: localMedia.contentType,
              }),
          )
        const body = {
          method: 'PUT',
          header: {
            'Content-Type': localMedia.contentType,
          },
          body: file,
        }
        try {
          const uploadResult = await fetch(saved.content.uploadUrl, body)
          console.log(
            `Upload result for position ${saved.position}`,
            uploadResult,
          )
        } catch (e) {
          console.log(`Upload failed for position ${saved.position}`)
          return false
        }

        let completed = saved.type !== 'VIDEO' // Not completed yet if VIDEO
        let failed = false
        let tries = 0
        while (!completed && !failed) {
          console.log(
            `Checking status for position ${saved.position}, try ${tries}`,
          )
          const status = await apiClient.event.media.videoStatus(
            saved.content.id,
            event.myGuest?.code,
          )
          if (status.status === 'CREATED') {
            completed = true
            console.log(
              `Completed uploading video for position ${saved.position}`,
            )
          } else if (status.status === 'FAILED') {
            failed = true
            console.log(`Failed uploading video for position ${saved.position}`)
          } else if (tries > 5) {
            console.log(
              `Video at position ${saved.position} not processed after 5 tries`,
            )
            failed = true
          }
          await sleep(2500)
          tries++
        }
        return completed
      }),
    )
    if (clearAfter) {
      setMedia([])
    }

    const fails = results.filter((r) => r === false)
    if (fails.length > 0) {
      if (fails.length === 1) {
        dispatch(setSnackbar('error', i18n.t('common:imageError.oneError')))
      } else {
        dispatch(
          setSnackbar(
            'error',
            i18n.t('common:imageError.countError', { count: fails.length }),
          ),
        )
      }
    }
    return fails.length
  }

  return { uploadImage, onRemoveImageClicked, uploadMedia }
}
